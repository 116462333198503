/*  Navbar */

/* Style the navigation menu */
.topnav {
	overflow: hidden;
	background-color: rgb(0, 0, 100);
	position: relative;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #myLinks {
	display: none;
}

/* Style navigation menu links */
.topnav a {
	color: white;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
	display: block;
}

/* Style the hamburger menu */
.topnav span.icon {
	background: red;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
}

/* Add a background color on mouse-over */
.topnav a:hover {
	background-color: rgb(0, 0, 128);
	color: white;
}

.menuicon {
	color: white;
	padding-left: 14px;
	margin-bottom: 25px;
	text-decoration: none;
	font-size: 17px;
	cursor: pointer;
	display: inline;
	vertical-align: 10px;
}

.menuicon:hover {
	background-color: rgb(0, 0, 128);
	color: white;
}


.titlelogocontainer {
	padding-left: calc(50% - 60px);
}

.titlelogo {
	padding-top: 5px;
}

.version {
vertical-align: 27px;
position: absolute;
	right: 0;

	width: 15px;

	top: 7px;
	color: white;
	font-size: 7px;
}

