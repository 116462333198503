.App {
  text-align: left;
  padding: 5px;
}

a.nobreak {
	display: inline;
}

a:link {
	color: rgb(89, 79, 223);
}

a:visited {
	color: rgb(78, 151, 224);
}

button {
	background-color: rgb(89, 79, 223);
	border: 2px solid black;
	color: white;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 5px;
}

.linkbutton {
	background-color: rgb(89, 79, 223);
	border: 2px solid black;
	color: white;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 5px;
}

a:link.linkbutton {
	color: white;
}

a:visited.linkbutton {
	color: white;
}


.errorMessage {
	color: red;
}

.startpagelist li {
	font-size: 26px;
	padding-top: 20px;
}

.center {
	text-align: center;
}
